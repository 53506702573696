import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';

export default class googleAuthAPI {
    public static async connectGoogleAuth() {
        let response;

        try {
            const axiosResponse = (await ApiController.get(ApiRoutes.google.auth)) as AxiosResponse;

            if (axiosResponse) {
                response = axiosResponse.data;
            }
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(response);
    }
}
